/* .slide-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 300ms, opacity 300ms;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 300ms, opacity 300ms;
}

.slide-reverse-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-reverse-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 300ms, opacity 300ms;
}

.slide-reverse-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-reverse-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 300ms, opacity 300ms;
} */
.carousel-enter {
  opacity: 0;
  transform: translateX(100%);
}

.carousel-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 300ms, opacity 300ms;
}

.carousel-exit {
  opacity: 1;
  transform: translateX(0);
}

.carousel-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 300ms, opacity 300ms;
}

.carousel-reverse-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.carousel-reverse-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 300ms, opacity 300ms;
}

.carousel-reverse-exit {
  opacity: 1;
  transform: translateX(0);
}

.carousel-reverse-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 300ms, opacity 300ms;
}

.no-pointer-events {
  opacity: 1;
  pointer-events: none; /* Disable interaction */
}

button:disabled {
  opacity: 1;
  cursor: not-allowed; /* Optional: show the not-allowed cursor */
}

@keyframes continuousProgress {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.continuous-progress-bar {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  overflow: hidden;
  border-radius: 5px;
}

.continuous-progress-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgb(24, 94, 157), rgb(45, 145, 195));
  animation: continuousProgress 1.3s linear infinite;
}